import axios from 'axios'
import communLangage from '@/commun/commun.langage.js';

export default {
     name: 'Projets',

     data() {
          return {
               projetEnCours:null
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          }
     },

     methods: {
          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/autre/autre.json').then(result => {
                    this.projetEnCours = this.langage == "fr" ? result.data.fr.enCoursCreation : result.data.en.enCoursCreation;

               });
          }
     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     created() {
          this.recupererTexte();
     }
}